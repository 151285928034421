.heading {
    margin-top      : 1em;
}

.container {
    display         : block;
    max-width       : 100%;
    overflow-x      : auto;
    overflow-y      : hidden;
    height          : 140px;
    white-space     : nowrap;
}

.item {
    display         : inline-block;
    width           : 128px;
}

.item a {
    text-decoration: none;
}

.item img {
    max-height: 128px;
}
