.bsStatusMessage {
    display     : block;
    position    : fixed;
    top         : 5vh;
    left        : 6vw;
    width       : 88vw;
    z-index     : 101;
    background  : rgba(0,0,0,0.75);
    margin      : 0;
    padding     : 0.5em;
    color       : #fff;
    font-family : Arial, Helvetica, sans-serif;
    font-size   : 16pt;
}