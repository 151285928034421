.greyOuterBox {
    background  : rgba(0,0,0,0.75);
    position    : fixed;
    top         : 0;
    left        : 0;
    margin      : 0;
    padding     : 0;
    width       : 100%;
    height      : 100%;
    z-index     : 100;
}

.whiteInnerBox {
    background  :white;
    position    : fixed;
    top         : 4vh;
    left        : 4vw;
    margin      : 0;
    padding     : 0;
    width       : 92vw;
    height      : calc( 100% - 8vh);
    overflow    : hidden;
    border      : 1px solid rgba(0,0,0,0.75);
}

.closeButton {
    display         : block;
    position        : fixed;
    z-Index         : 110;
    top             : calc( 4vh - 8px );
    left            : calc( 100% - 3vw - 24px);
};

.closeButtonImage {
    width           : 32px !important;
    height          : 32px !important;
};

.stopButton {
    display         : block;
    width           : 50vw;
    height          : 10vw;
    position        : fixed !important;
    top             : 80vh !important;
    left            : 25vw !important;
    background-color : rgba(255,128,128,1);
    border          : 1px solid rgba(255,128,128,0.5);
    z-Index         : 110  !important;
}
